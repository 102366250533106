import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal, NavLink } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import AddValue from "../../components/AddValue";
import { toastify } from "../../components/Toast";
import {
  addQuestion,
  deleteQuestion,
  getAllQuestions,
  getMatrixOfQuestion,
  getQuestionById,
  updateQuestion,
  getAllGoals,
  getQuestionPosition,
} from "../../redux/actions/authAction";

const ManageQuestions = () => {
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedParentQuestion, setSelectedParentQuestion] = useState(null);
  const [goal, setGoal] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [questionText, setQuestionText] = useState("");
  const [matrixData, setMatrixData] = useState([]);
  const [addQuestionModal, setAddQuestionModal] = useState(false);
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [resByMatrix, setResByMatrix] = useState(false);
  const [optionsForNewQuestion, setOptionsForNewQuestion] = useState({});
  const [questionPosition, setQuestionPosition] = useState();
  //edit question states
  const [selectedOptionForEdit, setSelectedOptionForEdit] = useState("");
  const [questionTextForEdit, setQuestionTextForEdit] = useState("");
  const [matrixDataForEdit, setMatrixDataForEdit] = useState([]);
  const [selectedParentQuestionForEdit, setSelectedParentQuestionForEdit] =
    useState(null);

  const [selectedParentQuestionIdForEdit, setSelectedParentQuestionIdForEdit] =
    useState(null);
  const [selectedCategoryForEdit, setSelectedCategoryForEdit] = useState("");
  const [editOptionChecker, setEditOptionChecker] = useState(false);
  const [editAllQuestionDataForMatrix, setEditAllQuestionDataForMatrix] =
    useState({});
  const [editOptionsAndScores, setEditOptionsAndScores] = useState([]);
  const [resByMatrixForEdit, setResByMatrixForEdit] = useState(false);
  const [matrixShowncheck, setMatrixShowncheck] = useState(false);
  const [questionPositionForEdit, setQuestionPositionForEdit] = useState();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [value, setValue] = useState("");
  const [score, setScore] = useState("");
  const [questionDataOnDelete, setQuestionDataOnDelete] = useState({});
  const { allQuestions, getQuestion, questionPositionsData, allGoalsData } =
    useSelector((state) => state.getQuestionData);

  useEffect(() => {
    dispatch(getAllQuestions());
    dispatch(getQuestionPosition());
    dispatch(getAllGoals());
    setQuestionTextForEdit(getQuestion.data?.questionText);
    setSelectedOptionForEdit(getQuestion?.data?.options?.optionType);
    setQuestionPositionForEdit(getQuestion?.data?.options?.optionType);
    setSelectedCategoryForEdit(getQuestion?.data?.qestionType);
    setGoal(getQuestion?.data?.goalData);
    setQuestionPositionForEdit(getQuestion?.data?.questionNumber);
    setSelectedParentQuestionForEdit(
      getQuestion?.data?.parentId?.questionText ?? "None"
    );

    setSelectedParentQuestionIdForEdit(getQuestion?.data?.parentId?._id);

    setEditOptionsAndScores(getQuestion?.data?.options?.values);
  }, [getQuestion]);
  const questionCategories = [
    "Physical",
    "Emotional",
    "Work Environment",
    "Basic Access",
    "Life Evaluation",
    "Health behaviour",
  ];
  const riskClasses = ["high", "average", "low"];
  const optionsTypes = [
    "Boolean",
    "Numbers",
    "Fields",
    // "Radio Buttons",
    "Check Boxes",
  ];
  const onRemoveAllEditQuestionData = () => {
    onEditQuestionModalClose();
    setMatrixDataForEdit([]);
  };
  const onSaveQuestionData = () => {
    if (questionText === "")
      return toastify("error", "Text filed is mandatory");
    if (selectedCategory === "")
      return toastify("error", "Category filed is mandatory");

    if (selectedOption === "")
      return toastify("error", "Option Type filed is mandatory");
    let isRiskClass = true;
    matrixData.forEach((item) => {
      if (!item?.riskClass) {
        isRiskClass = false;
        return;
      }
    });
    if (!isRiskClass)
      return toastify("error", "Risk Class for each case is mandatory");

    const wholeQuestionData = {
      questionText: questionText,
      qestionType: selectedCategory,
      questionNumber: questionPosition,
      options: {
        optionType: selectedOption,
        values: optionsForNewQuestion,
      },
      matrix: matrixData,
      goalId: goal._id,
      questionId: selectedParentQuestion === "" ? null : selectedParentQuestion,
    };
    dispatch(addQuestion(wholeQuestionData, callBackOfAddQuestion));
  };

  const onSaveEditQuestionData = () => {
    if (questionTextForEdit === "")
      return toastify("error", "Text filed is mandatory");
    if (selectedCategoryForEdit === "")
      return toastify("error", "Category filed is mandatory");

    if (selectedOptionForEdit === "")
      return toastify("error", "Option Type filed is mandatory");

    let isRiskClass = true;
    matrixDataForEdit.forEach((item, index) => {
      if (!item?.riskClass) {
        isRiskClass = false;
        return;
      }
    });

    if (!isRiskClass)
      return toastify("error", "Risk Class for each case is mandatory");

    if (questionTextForEdit || selectedCategoryForEdit) {
      const wholeQuestionData = {
        questionText: questionTextForEdit,
        qestionType: selectedCategoryForEdit,
        options: {
          optionType: selectedOptionForEdit,
          values: editOptionsAndScores,
        },
        questionNumber: questionPositionForEdit,
        matrix: {
          matrixTable:
            matrixDataForEdit?.length === 0
              ? getQuestion?.data?.matrixId?.matrixTable
              : matrixDataForEdit,
          matrixId: getQuestion?.data?.matrixId?._id,
        },
        goalId: goal?._id,
        // questionId: selectedParentQuestionForEdit,
      };
      dispatch(
        updateQuestion(
          getQuestion.data?._id,
          wholeQuestionData,
          callBackOfAddQuestion
        )
      );
    } else {
      toastify("error", "Question text and category is compulsory");
    }
  };

  const callBackOfAddQuestion = (res) => {
    if (res) {
      onAddQuestionModalClose();
      dispatch(getAllQuestions());
      dispatch(getAllGoals());
      setMatrixDataForEdit([]);
      onEditQuestionModalClose();
      setQuestionPosition("");
    }
    window.location.reload();
  };

  const addNarrativeRespone = (event, value) => {
    const matrix = matrixData.reduce((result, item) => {
      if (item.riskClassValue === value) {
        const ITEM = { ...item, narrativeResponse: event.target.value };
        result = [...result, ITEM];
        return result;
      } else {
        result = [...result, item];
        return result;
      }
    }, []);

    setMatrixData(matrix);
  };

  const addSolutionRespone = (event, value) => {
    const matrix = matrixData.reduce((result, item) => {
      if (item.riskClassValue === value) {
        const ITEM = { ...item, solutionResponse: event.target.value };
        result = [...result, ITEM];
        return result;
      } else {
        result = [...result, item];
        return result;
      }
    }, []);

    setMatrixData(matrix);
  };

  const addNarrativeResponeForEdit = (event, value) => {
    const matrix = matrixDataForEdit.reduce((result, item) => {
      if (item.riskClassValue === value) {
        const ITEM = { ...item, narrativeResponse: event.target.value };
        result = [...result, ITEM];
        return result;
      } else {
        result = [...result, item];
        return result;
      }
    }, []);

    setMatrixDataForEdit(matrix);
  };

  const addSolutionResponeForEdit = (event, value) => {
    const matrix = matrixDataForEdit.reduce((result, item) => {
      if (item.riskClassValue === value) {
        const ITEM = { ...item, solutionResponse: event.target.value };
        result = [...result, ITEM];
        return result;
      } else {
        result = [...result, item];
        return result;
      }
    }, []);

    setMatrixDataForEdit(matrix);
  };

  const addRiskClass = (event, value) => {
    const matrix = matrixData.reduce((result, item) => {
      if (item.riskClassValue === value) {
        const ITEM = { ...item, riskClass: event.target.value };
        result = [...result, ITEM];
        return result;
      } else {
        result = [...result, item];
        return result;
      }
    }, []);

    setMatrixData(matrix);
  };

  const addRiskClassForEdit = (event, value) => {
    const matrix = matrixDataForEdit.reduce((result, item) => {
      if (item.riskClassValue === value) {
        const ITEM = { ...item, riskClass: event.target.value };
        result = [...result, ITEM];
        return result;
      } else {
        result = [...result, item];
        return result;
      }
    }, []);
    setMatrixDataForEdit(matrix);
  };

  const onAddQuestionModalShow = () => {
    setAddQuestionModal(true);
  };
  const onAddQuestionModalClose = () => {
    setAddQuestionModal(false);
    setSelectedOption("");
    setSelectedParentQuestion("");
    setQuestionPosition();
    setSelectedCategory("");
    setQuestionText("");
    setMatrixData();
    setResByMatrix(false);
  };
  const onRemoveAllQuestionData = () => {
    onAddQuestionModalClose();
  };
  const onEditQuestionModalShow = (data) => {
    setEditQuestionModal(true);
    dispatch(getQuestionById(data._id, callBackForGetQuestion));
  };
  const callBackForGetQuestion = (res) => {
    if (res) {
    }
  };
  const onEditQuestionModalClose = () => {
    setEditQuestionModal(false);
    setEditOptionChecker(false);
    setMatrixShowncheck(false);
    setResByMatrixForEdit(false);
    setQuestionTextForEdit("");
    setSelectedOptionForEdit("");
    setSelectedCategoryForEdit("");
    setSelectedParentQuestionForEdit("");
  };

  const onEditOptionClick = (id) => {
    const newArr = editOptionsAndScores.filter((item) => item._id !== id);
    setEditOptionsAndScores(newArr);
  };
  const editOptionCheckerHandler = () => {
    setEditOptionChecker(true);
  };
  const onSaveValueAndScoreForEdit = () => {
    if (score && value) {
      setEditOptionsAndScores([
        ...editOptionsAndScores,
        { value, score, _id: (Math.random() + 1).toString(36).substring(7) },
      ]);
      setScore("");
      setValue("");
    }
  };
  useEffect(() => {
    setEditAllQuestionDataForMatrix(data);
  }, [editOptionsAndScores]);
  const onSaveEditMatrixData = () => {
    if (editOptionsAndScores?.length === 0)
      return toastify("error", "Plase add options");
    if (
      selectedOptionForEdit === "boolean" &&
      editOptionsAndScores.length !== 2
    )
      return toastify(
        "error",
        "You have to enter must two options against this selected option type"
      );
    if (editAllQuestionDataForMatrix) {
      dispatch(
        getMatrixOfQuestion(
          editAllQuestionDataForMatrix,
          callBackForQuestionMatrix
        )
      );
    }
    setResByMatrixForEdit(true);
    setMatrixShowncheck(!matrixShowncheck);
  };
  const data = {
    questionId: selectedParentQuestionIdForEdit ?? null,
    options: {
      optionType: selectedOptionForEdit,
      values: editOptionsAndScores,
    },
  };
  const callBackForQuestionMatrix = (res) => {
    if (res) {
      setResByMatrixForEdit(true);
      let finalArray = [];

      if (res?.data.length > getQuestion?.data?.matrixId?.matrixTable.length) {
        finalArray = res?.data.map((item, index) => {
          const elementFound = getQuestion?.data?.matrixId?.matrixTable.find(
            (element) => {
              if (areEqual(element?.origins, item?.origins)) {
                return element;
              }
            }
          );
          if (elementFound) {
            return elementFound;
          } else {
            return item;
          }
        });
      } else {
        finalArray = res?.data.map((item, index) => {
          const elementFound = getQuestion?.data?.matrixId?.matrixTable.find(
            (element) => {
              if (
                areEqual(element?.origins, item?.origins)
                // element?.origins[0] === item?.origins[0] &&
                // element?.origins[1] === item?.origins[1]
              ) {
                return element;
              }
            }
          );
          if (elementFound) {
            return elementFound;
          } else {
            return item;
          }
        });
      }
      setMatrixDataForEdit(getQuestion?.data?.matrixId?.matrixTable);
    }
  };

  function areEqual(array1, array2) {
    if (array1.length === array2.length) {
      return array1.every((element) => {
        if (array2.includes(element)) {
          return true;
        }

        return false;
      });
    }

    return false;
  }
  const deleteQuestionHandler = (data) => {
    setQuestionDataOnDelete(data);
    setDeleteConfirmationModal(true);
  };

  const onDeleteConfirmationModalClose = () => {
    setDeleteConfirmationModal(false);
  };
  const deleteQuestionConfirmationHandler = () => {
    dispatch(
      deleteQuestion(questionDataOnDelete._id, callBackForGetAllQuestions)
    );
  };
  const callBackForGetAllQuestions = () => {
    dispatch(getAllQuestions());
    dispatch(getAllGoals());
    setQuestionPosition("");
    setDeleteConfirmationModal(false);
  };

  return (
    <>
      {/* DELETE CONFIRMATION */}
      <>
        <Modal
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={deleteConfirmationModal}
          onHide={onDeleteConfirmationModalClose}
          centered
        >
          <Modal.Body>
            <h4>You really want to delete this question?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onDeleteConfirmationModalClose}>Close</Button>
            <Button onClick={deleteQuestionConfirmationHandler}>Delete</Button>
          </Modal.Footer>
        </Modal>
      </>

      {/* ADD NEW QUESTION */}
      <>
        <Modal
          size="lg"
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={addQuestionModal}
          onHide={onAddQuestionModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Add Question</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-modal-body">
            <>
              <Form>
                {/* Qustion text field */}
                <Form.Group
                  className="question-text mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="d-flex ">
                    <h5>Text</h5>
                  </Form.Label>
                  <Form.Control
                    className="question-text-input"
                    type="text"
                    rows={2}
                    as="textarea"
                    placeholder="Write your question text"
                    onChange={(e) => setQuestionText(e.target.value)}
                    value={questionText}
                  />
                </Form.Group>

                {/* Qustion Category field */}

                <div className="row">
                  <Form.Group
                    className="mb-3 question-category-form-group"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="">
                      <h5 style={{ width: "137%" }}>Category</h5>
                    </Form.Label>

                    <>
                      <form className="questionCategorySelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          onChange={(event) =>
                            setSelectedCategory(event.target.value)
                          }
                          value={selectedCategory}
                        >
                          <option hidden>Select Category</option>
                          {questionCategories?.map((categories, index) => {
                            return (
                              <>
                                <option
                                  value={
                                    categories === "Emotional"
                                      ? "emotional"
                                      : categories === "Physical"
                                      ? "physical"
                                      : categories === "Work Environment"
                                      ? "workEnvironment"
                                      : categories === "Basic Access"
                                      ? "basicAccess"
                                      : categories === "Life Evaluation"
                                      ? "lifeEvaluation"
                                      : categories === "Health behaviour"
                                      ? "healthyBehaviour"
                                      : ""
                                  }
                                >
                                  {categories}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                </div>

                {/* Parent Qustion  field */}

                {allQuestions?.data?.length !== 0 ? (
                  <div className="row">
                    <Form.Group
                      className="mb-3 question-category-form-group"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label className="">
                        <h5>To Combine</h5>
                      </Form.Label>

                      <form className="questionCategorySelectorFieldForm">
                        <select
                          id="parent-question-field"
                          className="goalSelectorField"
                          onChange={(event) =>
                            setSelectedParentQuestion(event.target.value)
                          }
                          value={selectedParentQuestion}
                        >
                          <option hidden>Select Question To Combine</option>
                          {allQuestions?.data?.map((ques, index) => {
                            return (
                              <>
                                <option value={ques._id}>
                                  {ques?.questionText}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </Form.Group>
                  </div>
                ) : null}

                <div className="row">
                  <Form.Group
                    className="mb-3 question-category-form-group"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="">
                      <h5>Goal</h5>
                    </Form.Label>

                    <form className="questionCategorySelectorFieldForm">
                      <select
                        id="parent-question-field"
                        className="goalSelectorField"
                        defaultValue={goal?.title}
                        onChange={(event) => {
                          const goalFound = allGoalsData?.data.find(
                            (goal) => goal._id === event.target.value
                          );
                          setGoal(goalFound);
                        }}
                        // value={goal?.title}
                      >
                        <option hidden>Select Goal</option>
                        {allGoalsData?.data?.map((goal, index) => {
                          return (
                            <>
                              <option value={goal?._id}>{goal?.title}</option>
                            </>
                          );
                        })}
                      </select>
                    </form>
                  </Form.Group>
                </div>
                <div className="goal-for-content mb-3">
                  <Form.Label className="d-flex ">
                    <h5>Position</h5>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setQuestionPosition(e.target.value)}
                    value={questionPosition}
                    aria-label="Default select example"
                  >
                    <option hidden>Select question's position</option>
                    {questionPositionsData?.data?.map((position, index) => {
                      return <option value={position}>{position}</option>;
                    })}
                    <option value={questionPositionsData?.data?.length + 1}>
                      {questionPositionsData?.data?.length + 1}
                    </option>
                    ;
                  </Form.Select>
                </div>
                {resByMatrix ? (
                  <></>
                ) : (
                  <>
                    <div className="row">
                      <Form.Group
                        className="mb-3 question-category-form-group"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label className="">
                          <h5>Options Type</h5>
                        </Form.Label>

                        <>
                          <form className="questionCategorySelectorFieldForm">
                            <select
                              className="goalSelectorField"
                              onChange={(event) =>
                                setSelectedOption(event.target.value)
                              }
                              value={selectedOption}
                            >
                              <option hidden>Select Option</option>
                              {optionsTypes?.map((options, index) => {
                                return (
                                  <>
                                    <option
                                      value={
                                        options === "Boolean"
                                          ? "boolean"
                                          : options === "Numbers"
                                          ? "numbers"
                                          : options === "Fields"
                                          ? "fields"
                                          : // : options === "Radio Buttons"
                                          // ? "radioButtions"
                                          options === "Check Boxes"
                                          ? "checkBoxes"
                                          : ""
                                      }
                                    >
                                      {options}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </form>
                        </>
                      </Form.Group>
                    </div>
                  </>
                )}

                <AddValue
                  selectedOption={selectedOption}
                  setMatrixData={setMatrixData}
                  selectedParentQuestion={selectedParentQuestion}
                  setResByMatrix={setResByMatrix}
                  resByMatrix={resByMatrix}
                  setOptionsForNewQuestion={setOptionsForNewQuestion}
                />
                <div>
                  {matrixData?.map((item) => (
                    <div className="d-flex">
                      <div className="mt-2 narative-response-data">
                        <>[ </> {item?.origins[0]},{item?.origins[1]}
                        <>] </>
                      </div>
                      <input
                        className="narativeResSelectorField"
                        placeholder="Narative response"
                        onBlur={(e, value = item.riskClassValue) =>
                          addNarrativeRespone(e, value)
                        }
                      />

                      <Form.Group
                        className="mb-3 question-category-form-group"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <>
                          <form className="questionCategorySelectorFieldForm">
                            <select
                              className="goalSelectorField"
                              onChange={(event, value = item.riskClassValue) =>
                                addRiskClass(event, value)
                              }
                              //  value={selectedRiskClass}
                            >
                              <option hidden>Select Risk level</option>
                              {riskClasses?.map((riskClass, index) => {
                                return (
                                  <option value={riskClass}>{riskClass}</option>
                                );
                              })}
                            </select>
                          </form>
                        </>
                      </Form.Group>

                      <input
                        className="narativeResSelectorField"
                        placeholder="Solution response"
                        onBlur={(e, value = item.riskClassValue) =>
                          addSolutionRespone(e, value)
                        }
                      />
                    </div>
                  ))}
                </div>

                <div className="questions-form-end-buttons">
                  <Button variant="secondary" onClick={onRemoveAllQuestionData}>
                    Cancel
                  </Button>
                  <Button
                    className="ml-3"
                    variant="primary"
                    onClick={onSaveQuestionData}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </>
          </Modal.Body>
          {/* <Modal.Footer>
              <Button variant="secondary" onClick={onAddQuestionModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onAddUserhandler}>
                Save
              </Button>
            </Modal.Footer> */}
        </Modal>
      </>

      {/* EDIT QUESTION */}
      <>
        <Modal
          size="lg"
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={editQuestionModal}
          onHide={onEditQuestionModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Edit Question</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-modal-body">
            <>
              <Form>
                <Form.Group
                  className="question-text mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="d-flex ">
                    <h5>Text</h5>
                  </Form.Label>
                  <Form.Control
                    className="question-text-input"
                    type="text"
                    rows={2}
                    as="textarea"
                    placeholder="Write your question text"
                    onChange={(e) => setQuestionTextForEdit(e.target.value)}
                    value={questionTextForEdit}
                  />
                </Form.Group>

                {/* question category */}

                <div className="row">
                  <Form.Group
                    className="mb-3 question-category-form-group"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="">
                      <h5 style={{ width: "137%" }}>Category</h5>
                    </Form.Label>

                    <>
                      <form className="questionCategorySelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          value={selectedCategoryForEdit}
                          onChange={(event) =>
                            setSelectedCategoryForEdit(event.target.value)
                          }
                          defaultValue={
                            selectedCategoryForEdit === "emotional"
                              ? "Emotional"
                              : selectedCategoryForEdit === "physical"
                              ? "Physical"
                              : selectedCategoryForEdit === "workEnvironment"
                              ? "Work Environment"
                              : selectedCategoryForEdit === "basicAccess"
                              ? "Basic Access"
                              : selectedCategoryForEdit === "lifeEvaluation"
                              ? "Life Evaluation"
                              : selectedCategoryForEdit === "healthBehaviour"
                              ? "Healthy behaviour"
                              : ""
                          }
                        >
                          <option hidden>Select Category</option>
                          {questionCategories?.map((categories, index) => {
                            return (
                              <>
                                <option
                                  value={
                                    categories === "Emotional"
                                      ? "emotional"
                                      : categories === "Physical"
                                      ? "physical"
                                      : categories === "Work Environment"
                                      ? "workEnvironment"
                                      : categories === "Basic Access"
                                      ? "basicAccess"
                                      : categories === "Life Evaluation"
                                      ? "lifeEvaluation"
                                      : categories === "Health behaviour"
                                      ? "healthyBehaviour"
                                      : ""
                                  }
                                >
                                  {categories}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                </div>

                {/* Parent Question Section */}
                <div className="row">
                  <Form.Group
                    className="question-text mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="d-flex ">
                      <h5>Combined With</h5>
                    </Form.Label>
                    <Form.Control
                      className="question-text-input"
                      type="text"
                      disabled
                      placeholder="Write your question text"
                      defaultValue={selectedParentQuestionForEdit}
                    />
                  </Form.Group>
                </div>

                <div className="row">
                  <Form.Group
                    className="mb-3 question-category-form-group"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="">
                      <h5>Goal </h5>
                    </Form.Label>

                    <form className="questionCategorySelectorFieldForm">
                      <select
                        id="parent-question-field"
                        className="goalSelectorField"
                        onChange={(event) => {
                          const goalFound = allGoalsData?.data.find(
                            (goal) => goal._id === event.target.value
                          );
                          setGoal(goalFound);
                        }}
                      >
                        <option hidden disabled>
                          {goal?.title}
                        </option>
                        <option value="" disabled>
                          Select Goal
                        </option>
                        <option>{goal?.title}</option>

                        {allGoalsData?.data?.map((questionGoal, index) => {
                          return (
                            <>
                              <option value={questionGoal?._id}>
                                {questionGoal?.title}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </form>
                  </Form.Group>
                </div>
                <div className="goal-for-content mb-3">
                  <Form.Label className="d-flex ">
                    <h5>Position</h5>
                  </Form.Label>
                  <Form.Select
                    onChange={(e) => setQuestionPositionForEdit(e.target.value)}
                    value={questionPositionForEdit}
                    aria-label="Default select example"
                  >
                    <option hidden disabled>
                      {questionPositionForEdit}
                    </option>
                    <option disabled>Select question's position</option>

                    {questionPositionsData?.data?.map((day, index) => {
                      return <option value={day}>{day}</option>;
                    })}
                  </Form.Select>
                </div>
                {editOptionChecker ? (
                  <>
                    <div className="row">
                      <Form.Group
                        className="mb-3 question-category-form-group"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label className="">
                          <h5>Options Type</h5>
                        </Form.Label>

                        <>
                          <form className="questionCategorySelectorFieldForm">
                            <select
                              className="goalSelectorField"
                              onChange={(event) => {
                                setSelectedOptionForEdit(event.target.value);
                                setEditOptionsAndScores([]);
                              }}
                              value={selectedOptionForEdit}
                            >
                              <option hidden>Select options</option>
                              {optionsTypes?.map((options, index) => {
                                return (
                                  <>
                                    <option
                                      value={
                                        options === "Boolean"
                                          ? "boolean"
                                          : options === "Numbers"
                                          ? "numbers"
                                          : options === "Fields"
                                          ? "fields"
                                          : // : options === "Radio Buttons"
                                          // ? "radioButtions"
                                          options === "Check Boxes"
                                          ? "checkBoxes"
                                          : ""
                                      }
                                    >
                                      {options}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </form>
                        </>
                      </Form.Group>
                    </div>

                    {selectedOptionForEdit ? (
                      <div className="add-value-main-container">
                        <div className="selectedOption">
                          <h5>
                            {selectedOptionForEdit === "boolean"
                              ? "Boolean"
                              : selectedOptionForEdit === "numbers"
                              ? "Numbers"
                              : selectedOptionForEdit === "fields"
                              ? "Fields"
                              : // : selectedOptionForEdit  === "Radio Buttons"
                              // ? "radioButtions"
                              selectedOptionForEdit === "checkBoxes"
                              ? "Check Boxes"
                              : ""}
                          </h5>
                        </div>
                        {resByMatrixForEdit ? (
                          <>
                            <>
                              <div className="options-modal-main-container">
                                {
                                  // coachClientsData?.data?.length !== 0
                                  // optionsAndScores?.data ? (
                                  <>
                                    <table>
                                      <thead>
                                        <tr
                                          className="header-row"
                                          style={{
                                            margin: "10px",
                                            height: "50px",
                                          }}
                                        >
                                          <th
                                            style={{
                                              width: "400px",
                                              textAlign: "center",
                                            }}
                                          >
                                            Option values
                                          </th>
                                          <th
                                            style={{
                                              width: "400px",
                                              textAlign: "center",
                                            }}
                                          >
                                            Scores
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {editOptionsAndScores?.map(
                                          (data, index) => {
                                            return (
                                              <tr
                                                className="whole-row-client"
                                                key={index}
                                                style={{
                                                  borderBottom:
                                                    "1px solid #f1f5f9",
                                                }}
                                              >
                                                <td>
                                                  <div
                                                    className="d-grid gap-2 d-md-block"
                                                    style={{
                                                      wordBreak: "break-word",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {data?.value}
                                                  </div>
                                                </td>

                                                <td
                                                  style={{
                                                    color: "#1b46b4",
                                                    width: "160px",
                                                    wordBreak: "break-word",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {data?.score}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </>
                                  // ) : (
                                  //   <div className="mt-5">
                                  //     <h3>No Questions yet</h3>
                                  //   </div>
                                  // )
                                }
                              </div>
                            </>
                          </>
                        ) : (
                          <>
                            <div className="options-container">
                              {editOptionsAndScores.map((data) => {
                                return (
                                  <>
                                    <p>
                                      Option:{data?.value} Score :{data?.score}
                                      <Button
                                        className="ml-2"
                                        size="sm"
                                        variant="outline-danger"
                                        onClick={() =>
                                          onEditOptionClick(data?._id)
                                        }
                                      >
                                        X
                                      </Button>
                                    </p>
                                  </>
                                );
                              })}
                            </div>
                            <div className=" value-and-scoring-inputs">
                              <input
                                className="valueAndScoreSelectorField"
                                placeholder="Enter value"
                                onChange={(event) =>
                                  setValue(event.target.value)
                                }
                                value={value}
                              />
                              <input
                                type="number"
                                className="valueAndScoreSelectorField"
                                placeholder="Enter score"
                                onChange={(event) =>
                                  setScore(event.target.value)
                                }
                                value={score}
                              />

                              <Button
                                className="ml-3 mb-1"
                                size="sm"
                                variant="outline-primary"
                                onClick={onSaveValueAndScoreForEdit}
                              >
                                +
                              </Button>

                              <Button
                                className="ml-3"
                                variant="primary"
                                onClick={onSaveEditMatrixData}
                              >
                                done
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <Button
                    variant="secondary"
                    onClick={editOptionCheckerHandler}
                  >
                    Edit options
                  </Button>
                )}

                {matrixShowncheck ? (
                  <div>
                    {matrixDataForEdit?.map((item) => {
                      return (
                        <div className="d-flex">
                          <div className="mt-2 narative-response-data">
                            <>[</>
                            {item?.origins[0]},{item?.origins[1]}
                            <>]</>
                          </div>
                          <input
                            className="narativeResSelectorField"
                            placeholder="Narative response"
                            defaultValue={item?.narrativeResponse}
                            onBlur={(e, value = item.riskClassValue) =>
                              addNarrativeResponeForEdit(e, value)
                            }
                          />

                          <Form.Group
                            className="mb-3 question-category-form-group"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <>
                              <form className="questionCategorySelectorFieldForm">
                                <select
                                  className="goalSelectorField"
                                  onChange={(
                                    event,
                                    value = item.riskClassValue
                                  ) => addRiskClassForEdit(event, value)}
                                  value={item?.riskClass}
                                >
                                  <option value="" hidden>
                                    Select Risk level
                                  </option>
                                  <option hidden disabled>
                                    {item?.riskClass}
                                  </option>
                                  {riskClasses?.map((riskClass, index) => {
                                    return (
                                      <option value={riskClass}>
                                        {riskClass}
                                      </option>
                                    );
                                  })}
                                </select>
                              </form>
                            </>
                          </Form.Group>

                          <input
                            className="narativeResSelectorField"
                            placeholder="Solution response"
                            defaultValue={item?.solutionResponse}
                            onBlur={(e, value = item.riskClassValue) =>
                              addSolutionResponeForEdit(e, value)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}

                <div className="questions-form-end-buttons">
                  <Button
                    variant="secondary"
                    onClick={onRemoveAllEditQuestionData}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="ml-3"
                    variant="primary"
                    onClick={onSaveEditQuestionData}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </>
          </Modal.Body>
          {/* <Modal.Footer>
              <Button variant="secondary" onClick={onAddQuestionModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onAddUserhandler}>
                Save
              </Button>
            </Modal.Footer> */}
        </Modal>
      </>

      <div className="manage-questions">
        <div className="row mb-3 pt-5 pl-5">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex">
              Manage Questions
            </h1>
          </div>
          <div
            className="col-md-6 d-flex justify-content-end"
            style={{ paddingRight: "65px" }}
          >
            <div>
              <button
                className="btn-coach me-4"
                onClick={onAddQuestionModalShow}
              >
                Add Question
              </button>
            </div>
          </div>
        </div>
        {/* <div className="row p-5">
          <div className="questions-page-main-container">
            <>
              <Form>
                <Form.Group
                  className="question-text mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="d-flex ">
                    <h5>Write your question text</h5>
                  </Form.Label>
                  <Form.Control
                    className="question-text-input"
                    type="text"
                    placeholder="Question text"
                    onChange={(e) => setQuestionText(e.target.value)}
                    value={questionText}
                  />
                </Form.Group>
                <div className="row">
                  <Form.Group
                    className="mb-3 question-category-form-group"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="">
                      <h5 style={{ width: "137%" }}>
                        Select Question category
                      </h5>
                    </Form.Label>

                    <>
                      <form className="questionCategorySelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          onChange={(event) =>
                            setSelectedCategory(event.target.value)
                          }
                          value={selectedCategory}
                        >
                          <option value="" disabled className="first-options">
                            Select categories
                          </option>
                          {questionCategories?.map((categories, index) => {
                            return (
                              <>
                                <option value={categories}>{categories}</option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group
                    className="mb-3 question-category-form-group"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="">
                      <h5>Select option type</h5>
                    </Form.Label>

                    <>
                      <form className="questionCategorySelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          onChange={(event) =>
                            setSelectedOption(event.target.value)
                          }
                          value={selectedOption}
                        >
                          <option value="" disabled className="first-options">
                            Select options
                          </option>
                          {optionsTypes?.map((options, index) => {
                            return (
                              <>
                                <option value={options}>{options}</option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                </div>
                <AddValue
                  selectedOption={selectedOption}
                  setAllQuestionDataForMatrix={setAllQuestionDataForMatrix}
                />
                <div>
                  {" "}
                  {matrixData?.map((item) => (
                    <div className="d-flex">
                      <div className="mt-2">
                        {item?.origins[0]},{item?.origins[1]} =
                      </div>
                      <input
                        className="narativeResSelectorField"
                        placeholder="Narative response"
                        onBlur={(e, value = item.riskClassValue) =>
                          addNarrativeRespone(e, value)
                        }
                      />

                      <Form.Group
                        className="mb-3 question-category-form-group"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <>
                          <form className="questionCategorySelectorFieldForm">
                            <select
                              className="goalSelectorField"
                              onChange={(event, value = item.riskClassValue) =>
                                addRiskClass(event, value)
                              }
                              // value={selectedRiskClass}
                            >
                              <option
                                value=""
                                disabled
                                className="first-options"
                              >
                                Select Risk level
                              </option>
                              {riskClasses?.map((riskClass, index) => {
                                return (
                                  <>
                                    <option value={riskClass}>
                                      {riskClass}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </form>
                        </>
                      </Form.Group>
                    </div>
                  ))}
                </div>

                <div className="questions-form-end-buttons">
                  <Button variant="secondary" onClick={onRemoveAllQuestionData}>
                    Cancel
                  </Button>
                  <Button
                    className="ml-3"
                    variant="primary"
                    onClick={onSaveQuestionData}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </>
          </div>
        </div> */}
        <>
          <div
            className="coach-clients-page "
            style={{ padding: "2% 3% 0% 3%" }}
          >
            <>
              <div className="clients-page-main-container">
                {
                  // coachClientsData?.data?.length !== 0
                  allQuestions?.data?.length ? (
                    <>
                      <table>
                        <thead>
                          <tr
                            className="header-row"
                            style={{ margin: "10px", height: "50px" }}
                          >
                            <th
                              style={{
                                textAlign: "left",
                                width: "100px",
                              }}
                            ></th>
                            <th
                              style={{
                                width: "600px",
                                textAlign: "left",
                              }}
                            >
                              Question text
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "400px",
                              }}
                            >
                              Question category
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "400px",
                              }}
                            >
                              Option values
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                width: "400px",
                              }}
                            >
                              Option scores
                            </th>
                            <th
                              style={{
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                              }}
                            ></th>
                          </tr>
                        </thead>

                        <tbody>
                          {allQuestions?.data?.map((data, index) => {
                            return (
                              <tr
                                className="whole-row-client"
                                key={index}
                                style={{
                                  borderBottom: "1px solid #f1f5f9",
                                }}
                              >
                                <td
                                  style={{
                                    paddingTop: "15px",
                                    paddingLeft: "25px",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.questionNumber}.
                                </td>

                                <td
                                  style={{
                                    paddingTop: "15px",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.questionText}
                                </td>

                                <td>
                                  <div
                                    className="d-grid gap-2 d-md-block"
                                    style={{
                                      textAlign: "left",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {data?.qestionType === "emotional"
                                      ? "Emotional"
                                      : data?.qestionType === "physical"
                                      ? "Physical"
                                      : data?.qestionType === "workEnvironment"
                                      ? "Work Environment"
                                      : data?.qestionType === "basicAccess"
                                      ? "Basic Access"
                                      : data?.qestionType === "lifeEvaluation"
                                      ? "Life Evaluation"
                                      : data?.qestionType === "healthyBehaviour"
                                      ? "Healthy Behaviour"
                                      : ""}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    paddingLeft: "12px",
                                    color: "#1b46b4",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.options?.values?.map(
                                    (valueData, index) => {
                                      return <p>{valueData?.value}</p>;
                                    }
                                  )}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: "12px",
                                    color: "#1b46b4",
                                    textAlign: "left",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {data?.options?.values?.map(
                                    (valueData, index) => {
                                      return <p>{valueData?.score}</p>;
                                    }
                                  )}
                                </td>
                                <td
                                  style={{
                                    width: "133px",
                                  }}
                                >
                                  <div className="clients-data-btn">
                                    <Dropdown>
                                      <Dropdown.Toggle id="toggle-btn-clients">
                                        <img
                                          src="/img/client-detail-btn.png"
                                          alt="btn-img"
                                          style={{ backgroundColor: "white" }}
                                        />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="dropdown-options-container">
                                        <Dropdown.Item className="dropdown-options">
                                          <NavLink
                                            className="create-activity-font"
                                            to="/coach-activities"
                                            onClick={() => {
                                              onEditQuestionModalShow(data);
                                            }}
                                          >
                                            Edit
                                          </NavLink>
                                          <NavLink
                                            className="create-activity-font"
                                            to="/coach-activities"
                                            onClick={() => {
                                              deleteQuestionHandler(data);
                                            }}
                                          >
                                            Delete
                                          </NavLink>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <div className="mt-5">
                      <h3>No Question yet</h3>
                    </div>
                  )
                }
              </div>
            </>
          </div>
        </>
      </div>
    </>
  );
};

export default ManageQuestions;
